import { BASE_URL } from 'config/env';
import { ready } from 'global-utils/doc-state';

const getScript = (
  source: string,
  id: string,
  userLocation?: string,
  userContinent?: string
) => {
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    ready(() => {
      const script = document.createElement('script');
      const cgbScripts = document.querySelectorAll('[id^="cbg"]');
      const lastCbgScript = cgbScripts[cgbScripts.length - 1];

      if (id) {
        script.setAttribute('id', id);
      }

      // eslint-disable-next-line func-names
      script.onerror = function () {
        console.error(`Error loading ${this.src}`);
      };

      if (userLocation) {
        script.setAttribute('location', userLocation);
      }

      if (userContinent) {
        script.setAttribute('continent', userContinent);
      }

      script.src = source;
      lastCbgScript.after(script);
    });
  } else {
    console.warn(`script ${id} is already on page`);
  }
};

export const initCConsent = (userLocation: string, userContinent: string) => {
  getScript(
    `${BASE_URL}cbgCConsent.js`,
    'cbgCConsent',
    userLocation,
    userContinent
  );
};

const useLegacyGatewayDomains = [
  'www.svyturyswebshop.lt',
  'www.aldaraveikals.lv',
  'www.sakuwebshop.ee',
  'www.lbconline.la',
  'www.mycarlsbergonline.my',
  'cadi.carlsberggroup.com',
  'signup.carlsbergmarstons.co.uk',
  'onboard.carlsberggroup.com',
  'blivkunde.carlsbergdanmark.dk',
  'signup.carlsbergsverige.se',
  'blikunde.ringnes.no',
  'order.carlsbergmarstons.co.uk',
  'shop.carlsbergdanmark.dk',
  'shop.ringnes.no',
  'shop.sinebrychoff.fi',
  'carlsbergonline.se',
  'shop.feldschloesschen.swiss',
  'dev-carlsberg-group-2022.euwest01.umbraco.io'
];

export const initAGateway = (userLocation: string) => {
  const currentDomain = window.location.host;
  const isLegacyDomain = useLegacyGatewayDomains.includes(currentDomain);
  const scriptSrc = isLegacyDomain
    ? `${BASE_URL}cbgAGateway.js`
    : `${BASE_URL}cbgAGateway_v2.js`;
  getScript(scriptSrc, 'cbgAGateway', userLocation);
};
